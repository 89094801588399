<template>
    <b-row>
        <b-col cols="12" md="8">
            <b-card no-body>
                <b-card-body v-if="!badRequest">
                    <h6 class="mb-2">{{i18nT(`Survey Results`)}}</h6>
                    <b-row class="results-boxes">
                        <b-col cols="12" md="6">
                            <h2>{{ survey.Label }}</h2>
                            <p v-html="survey.Description"></p>
                        </b-col>
                        <b-col cols="4" md="2">
                            <div class="border p-1 text-center">
                                <label class="m-t-10 -m-b-5">{{i18nT(`Audience`)}}</label>
                                <h3 class="m-t-5 -m-b-10">{{ results.audience_count }}</h3>
                            </div>
                        </b-col>

                        <b-col cols="4" md="2">
                            <div class="border p-1 text-center">
                                <label class="m-t-10 -m-b-5">{{i18nT(`Views`)}}</label>
                                <h3 class="m-t-5 -m-b-10">{{ results.opened_count }}</h3>
                            </div>
                        </b-col>

                        <b-col cols="4" md="2">
                            <div class="border p-1 text-center">
                                <label class="m-t-10">{{i18nT(`Participants`)}}</label>
                                <h3 class="m-t-5 -m-b-10">{{ results.participation_count }}</h3>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="py-2" v-if="isFreelancer" >
                        <b-col cols="12" md="6" offset="6">
                            <b-form-checkbox
                                :checked="showOnlyMyAnswers"
                                v-model="showOnlyMyAnswers"
                                name="check-button"
                                switch
                                inline
                                @change="showMine()"
                            >
                                {{i18nT(`Show only my answers`)}}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>

                    <b-row class="py-2" v-else>
                        <b-col cols="12" md="6">
                            <b-form-group
                                :label="i18nT(`Filter by audience groups`)"
                                label-for="audienceGroups"
                            >
                                <v-select
                                    v-model="audienceGroup"
                                    :options="audienceGroups"
                                    :reduce="(e) => (e.value == '' ? '' : e.value)"
                                    label="text"
                                    id="audienceGroups"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group
                                :label="i18nT(`Filter by participants`)"
                                label-for="participants"
                            >
                                <v-select
                                    :disabled="audienceGroup === ''"
                                    v-model="participant"
                                    :options="participants"
                                    label="text"
                                    id="participants"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row
                        class="border-top py-2"
                        v-for="question in survey.Questions"
                        :key="question.Id"
                    >
                        <b-col cols="6" md="4">
                            <h4>{{ question.Label }}</h4>
                            <p>{{ question.Description }}</p>
                            <b-tabs align="left" content-class="mt-3">
                                <b-tab title="Pie Chart" active>
                                    <template #title>
                                        <feather-icon icon="PieChartIcon" size="14" />
                                        <span>{{i18nT(`Pie Chart`)}}</span>
                                    </template>
                                    <vue-apex-charts
                                        type="donut"
                                        height="350"
                                        :options="donutChart[question.Id].chartOptions"
                                        :series="donutChart[question.Id].series"
                                    />
                                </b-tab>
                                <b-tab>
                                    <template #title>
                                        <feather-icon icon="BarChartIcon" size="14" />
                                        <span>{{i18nT(`Bar Chart`)}}</span>
                                    </template>
                                    <vue-apex-charts
                                        type="bar"
                                        height="350"
                                        :options="barChart[question.Id].chartOptions"
                                        :series="barChart[question.Id].series"
                                    />
                                </b-tab>
                            </b-tabs>
                        </b-col>
                        <b-col cols="6" md="8">
                            <h5 class="mb-2">{{i18nT(`Answer details`)}}</h5>
                            <b-table striped hover :items="items[question.Id]"></b-table>
                        </b-col>
                    </b-row>
                </b-card-body>
                <b-card-body v-else>
                        <h4>{{i18nT(`Invalid survey`)}}</h4>
                </b-card-body>
            </b-card>
        </b-col>
        <b-col cols="12" md="4">
            <b-card no-body>
                <b-card-body>
                    <h6 class="mb-2">{{i18nT(`Information`)}}</h6>
                    <b-row class="border-bottom pb-2">
                        <b-col cols="12">
                            <ul class="bg-light text-dark p-2" style="list-style: none">
                                <li>
                                    <feather-icon icon="CalendarIcon" size="16" /> Created:
                                    <strong>{{ survey.UpdatedAt | date }}</strong>
                                </li>
                                <li>
                                    <feather-icon icon="PieChartIcon" size="16" /> Last Updated:
                                    <strong>{{ survey.CreatedAt | date }}</strong>
                                </li>
                                <li v-if="survey.SentAt !== null">
                                    <feather-icon icon="SendIcon" size="16" /> Last Sent:
                                    <strong>{{ survey.SentAt | date }}</strong>
                                </li>
                            </ul>
                        </b-col>
                        <b-col cols="12">
                            <p>{{i18nT(`Survey url`)}}</p>
                            <p class="text-muted">
                                {{i18nT(`This is the unique job offer page link where candidates can apply for this position. use and share this link on the job boards where you publish your offer.`)}}:
                            </p>
                        </b-col>
                        <b-col cols="12">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-button size="sm"
                                    ><feather-icon icon="ClipboardIcon" size="18"
                                    /></b-button>
                                </b-input-group-prepend>

                                <b-form-input v-model="publicUrl"></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BButton,
    // BButtonGroup,
    // BAlert,
    // BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BForm,
    BCard,
    // BLink,
    BFormCheckbox,
    // BFormCheckboxGroup,
    // BFormTextarea,
    BCardBody,
    // BFormSelect,
    BInputGroup,
    // BInputGroupText,
    BInputGroupPrepend,
    // BFormRadio,
    // BListGroupItem,
    BTable,
    BTabs,
    BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
// import { ValidationProvider, ValidationObserver } from "vee-validate";
// import { required } from "@validations";
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import router from "@/router";
import useAuth from "@/auth/useAuth";
import { dictToSelectArray } from '@core/utils/utils'

// import draggable from "vuedraggable";
import VueApexCharts from "vue-apexcharts";
import apexChatData from "./apexChartData";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        VueApexCharts,
        BButton,
        // BButtonGroup,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        // BForm,
        BCard,
        // BLink,
        BCardBody,
        vSelect,
        // BFormTextarea,
        // BFormSelect,
        // BAlert,
        // BBadge,
        BFormCheckbox,
        // BFormCheckboxGroup,
        // ValidationProvider,
        // ValidationObserver,
        BInputGroup,
        // BInputGroupText,
        BInputGroupPrepend,
        // BFormRadio,
        // BListGroupItem,
        // draggable,
        BTable,
        BTabs,
        BTab,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            apexChatData,
            survey: {},
            results: [],
            audienceGroups: [],
            audienceGroup: {text: this.i18nT(`All`), value: ''},
            participants: [],
            participant: null,
            items: {},
            donutChart: {},
            barChart: {},
            publicUrl: "",
            isFreelancer: false,
            showOnlyMyAnswers: false,
            badRequest: false
        };
    },

    async created() {
        let userType = useAuth.getUserType();
        this.isFreelancer = userType === "Freelancer"

        const id = this.$route.params.id;
        if (id !== "new") {

            let opts = {
                surveyId: id,
            }

            await this.refetchData(opts);

            await this.$http.get(`surveys/audienceGroups`).then(({ data }) => {
                const audienceGroups = data.data;
                for (const property in audienceGroups) {
                    if (this.results.audience_group && this.results.audience_group.Id == property)
                        this.audienceGroup = {
                            value: property,
                            text: audienceGroups[property],
                        };
                    this.audienceGroups.push({
                        value: property,
                        text: audienceGroups[property],
                    });
                }
                this.audienceGroups.unshift({
                    value: '',
                    text: this.i18nT(`All`),
                })
            });
        }
    },
    methods: {

        refetchData(opts){
            let requestUrl = `surveys/results?id=${opts.surveyId}`;
            if(opts.participantId)
                requestUrl += `&participantId=${opts.participantId}`;
            if(opts.audienceGroupId)
                requestUrl += `&audienceGroupId=${opts.audienceGroupId}`;

            return new Promise((resolve, reject) => {

                this.$http.get(requestUrl).then(({ data }) => {
                    this.survey = data.data.survey;
                    this.results = data.data.results;
                    this.publicUrl = window.location.href;

                    this.participants = dictToSelectArray(data.data.results.participants);

                    for (const property in this.results.survey_answers_labels) {
                        const labels = this.results.survey_answers_labels[property];
                        const votes = this.results.survey_answers_votes[property];
                        const percents = this.results.survey_answers_percents[property];
                        const tableData = [];
                        const chartData = [];
                        const chartLabels = [];

                        this.donutChart[property] = JSON.parse(
                            JSON.stringify(apexChatData.donutChart)
                        );
                        this.barChart[property] = JSON.parse(
                            JSON.stringify(apexChatData.barChart)
                        );

                        this.donutChart[property].chartOptions.labels = [];
                        this.donutChart[property].series = [];
                        this.barChart[property].chartOptions.xaxis = [];
                        this.barChart[property].series = [];

                        for (const subProp in labels) {
                            tableData.push({
                                answer: labels[subProp],
                                votes: votes[subProp] ? votes[subProp] : 0,
                                percents: percents[subProp] ? `${percents[subProp]}%` : `0%`,
                            });
                            chartLabels.push(labels[subProp]);
                            chartData.push(percents[subProp] ? percents[subProp] : 0);
                        }
                        this.donutChart[property].chartOptions.labels = chartLabels;
                        this.donutChart[property].series = chartData;
                        this.barChart[property].chartOptions.xaxis = {
                            categories: chartLabels,
                        };
                        this.barChart[property].series = [{ data: chartData }];
                        this.items[property] = tableData;
                    }

                    resolve(data);

                }).catch((err) => {
                    const code = err.response.data.code
                    if (code === 403) {
                        this.badRequest = true
                    }
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })

                    // reject(err);
                });
            });
        },

        showMine(){
            let opts = {
                surveyId: this.$route.params.id,
            }

            if(this.showOnlyMyAnswers){
                let curUserId = useAuth.getProperUserId();
                opts.participantId = curUserId;
            }

            this.refetchData(opts);
        },
        fetchParticipantsInGroup(groupId){
            let surveyId = this.$route.params.id;
            this.$http.get(`surveys/audienceMembers?group_id=${groupId}&survey_id=${surveyId}`).then(({ data }) => {
                this.participants = dictToSelectArray(data.data);
            }).catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
            });
        },
    },
    watch: {
        audienceGroup: {
            handler: function (val) {
                let opts = {
                    surveyId: this.$route.params.id,
                }

                if (val == '') {
                    this.participant = {text: this.i18nT(`All`), value: ''}
                    this.participants = [
                        {text: this.i18nT(`All`), value: ''}
                    ];
                    this.refetchData(opts);
                } else {
                    this.fetchParticipantsInGroup(val);
                    this.participants = dictToSelectArray(this.results.participants);
                    this.participant =  {text: this.i18nT(`All`), value: ''}
                    opts.audienceGroupId = val;
                    this.refetchData(opts);
                }
            },
            deep: true,
        },
        participant: {
            handler: function (val, oldVal) {
                let opts = {
                    surveyId: this.$route.params.id,
                }
                if (oldVal!==val && val == '') {
                    opts.audienceGroupId = this.audienceGroup.value;
                    this.refetchData(opts);
                } else {
                    opts.participantId = val;
                    this.refetchData(opts);
                }
            },
            deep: true,
        },
    }
};
</script>

<style lang="scss">
.section-title {
    font-size: 11px;
}
.document-name {
    display: inline;
    line-height: 32px;
}
.document-actions-bar {
    float: right;
}

.results-boxes label {
    font-size: 0.85em !important;
}

</style>
